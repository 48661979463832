<template>
  <div
    v-if="isVisible && referralCodeStatus === 'EXPIRED'"
    class="tw-bg-nl-green-100 tw-h-[45px] tw-text-nl-yellow-100 tw-font-agenda tw-flex tw-items-center tw-justify-center tw-uppercase tw-font-semibold tw-tracking-wide tw-text-center tw-leading-[1.04] tw-px-5"
  >
    {{ expiredMessageBody }}
  </div>
  <div
    v-else-if="isVisible && messageBody"
    class="tw-bg-nl-green-100 tw-h-[45px] tw-text-nl-yellow-100 tw-font-agenda tw-flex tw-flex-col tw-items-center tw-justify-center tw-uppercase tw-font-semibold tw-tracking-wide tw-text-center tw-leading-[1.04] tw-px-5 max-md:tw-text-[13px]"
  >
    <span class="sm:tw-inline md:tw-block tw-max-h-38 tw-overflow-hidden" v-html="messageBody"></span>
    <span class="sm:tw-inline md:tw-block tw-max-h-38 tw-overflow-hidden tw-text-[87.5%] md:tw-pl-2 tw-font-normal" v-if="messageFineprint"
          v-html="messageFineprint"></span>
  </div>
  <div
    v-else-if="isVisible && referrer && maxAmountPerOrder"
    class="tw-bg-nl-green-100 tw-h-[45px] tw-text-nl-yellow-100 tw-font-agenda tw-flex tw-flex-col tw-items-center tw-justify-center tw-uppercase tw-font-semibold tw-tracking-wide tw-text-center tw-leading-[1.04] tw-px-5 max-md:tw-text-[13px]"
  >
    <span class="sm:tw-inline md:tw-block tw-max-h-38 tw-overflow-hidden">
          Your friend {{ referrer.firstName }} gave you {{
        maxAmountPerOrder
      }} off your {{ totalWeeksForReferralFullAmount }}<span
      v-if="splitAndReceiverAreNotSameAmount"> ({{ receiverBonus }} total)</span>! Applies at checkout.
        </span>
    <span class="sm:tw-inline md:tw-block tw-max-h-38 tw-overflow-hidden tw-text-[87.5%] md:tw-pl-2 tw-font-normal" v-if="messageFineprint"
          v-html="messageFineprint"></span>
  </div>
</template>
<script setup lang="ts">
import {roundPriceIfDecimal} from '@/utils/helpers'

const appSettingsStore = useAppSettingsStore();
const cartStore = useCartStore();
const route = useRoute();
const { bannerTypeToShow, referralCodeStatus, referee, referrer, referralCode } = storeToRefs(cartStore);

const isVisible = computed(() => {
  const hideOnRoutes = [
    'checkout-processing',
    'success',
    'success-partner'
  ]

  if (hideOnRoutes.includes(route.name)) {
    return false
  }

  if (referralCodeStatus.value === 'EXPIRED') {
    if (bannerTypeToShow.value === 'discount') {
      // if there is a promo banner, we don't want to show an expired banner too
      return false;
    }
    // only return true when its expired and no other banner exist.
    return true;
  }

  return !!referralCode.value && bannerTypeToShow.value === 'raf'
})

watch(isVisible, (newValue) => {
  window.referralBannerIsVisible = newValue;
});

const receiverBonus = computed(() => {
  return roundPriceIfDecimal(appSettingsStore.referralSystemDiscounts?.referral)
})
const maxAmountPerOrder = computed(() => {
  return roundPriceIfDecimal(appSettingsStore.referralSystemDiscounts?.max_amount_per_order)
})
const totalWeeksForReferralFullAmount = computed(() => {
  return appSettingsStore.referralSystemDiscounts?.total_weeks_for_referral_full_amount
})
const splitAndReceiverAreNotSameAmount = computed(() => {
  return receiverBonus != maxAmountPerOrder
})
const messageBody = computed(() => {
  const message = referee.value?.firstName
    ? appSettingsStore.referralSystemDiscounts?.raf_float_banner_text_with_name
    : appSettingsStore.referralSystemDiscounts?.raf_float_banner_text

  if (!message) {
    return null
  }

  return message
    .replaceAll('[referee_first_name]', referee.value?.firstName ?? '')
    .replaceAll('[referrer_first_name]', referrer.value?.firstName)
})
const expiredMessageBody = computed(() => {
  return appSettingsStore.referralSystemDiscounts?.raf_float_banner_expired_text
})
const messageFineprint = computed(() => {
  return appSettingsStore.referralSystemDiscounts?.raf_float_banner_fineprint
})

onMounted(() => {
  window.referralBannerIsVisible = isVisible.value;
})
</script>
<style scoped>
@import url("~/assets/css/app.css");
</style>
