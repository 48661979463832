<script setup lang="ts">
import PromoCodeTierValue from "~/components/TopBanner/Promos/PromoCodeTierValue.vue";
import TierBoxIcon from "~/components/Icon/TierBoxIcon.vue";

const props = defineProps({
  tier: {
    required: true
  }
});
</script>

<template>
  <div class="tw-flex tw-flex-col tw-items-center sm:tw-flex-row sm:tw-space-x-1 tw-font-agenda">
    <div class="tw-relative">
      <TierBoxIcon/>
    </div>
    <PromoCodeTierValue :tier="props.tier"/>
  </div>
</template>
<style scoped>
@import url("~/assets/css/app.css");
</style>
