<script setup lang="ts">
import PromoCodeTierItem from "~/components/TopBanner/Promos/PromoCodeTierItem.vue";

const cartStore = useCartStore();
const onboardingStore = useOnboardingStore();

const isVisible = computed(() => {
  return onboardingStore.hasPromoCode && onboardingStore.hasPromoCodeTiersBanner && cartStore.bannerTypeToShow === 'discount'
});

const finalBoldText = () => {
  return cartStore.promoCodeBanner?.boldText
}
const finalNonBoldText = () => {
  return cartStore.promoCodeBanner?.nonBoldText
}
const finalBgColor = () => {
  return cartStore.promoCodeBanner?.bgColor
}
const finalTextColor = () => {
  return cartStore.promoCodeBanner?.color
}
const bannerDescription = computed(() => {
  return cartStore.promoCodeBanner?.description;
});
</script>

<template>
  <div class="tw-sticky tw-top-0 tw-right-0 tw-left-0 tw-z-50 floating-bar-promo promo-banner tw-bg-nl-green-100 tw-pt-[6px] tw-pb-1 md:tw-py-4 tw-normal-case" v-if="isVisible">
    <div class="md:tw-mx-auto md:tw-flex md:tw-w-[95%] md:tw-items-center md:tw-justify-around md:tw-space-x-[30px]">
      <h4
        class="tw-text-center tw-font-agenda tw-text-[12px] tw-font-semibold tw-text-nl-yellow-100 sm:tw-text-base md:tw-hidden"
      >
        {{ cartStore.promoCodeBanner.description }}
      </h4>
      <ul class="tw-mt-2 tw-flex tw-items-center tw-justify-center tw-gap-x-[10px] md:tw-mt-0 tw-font-agenda">
        <li class="tw-hidden md:tw-block">
          <h4 class="tw-font-agenda tw-text-[12px] tw-font-bold tw-text-nl-yellow-100 sm:tw-text-base tw-normal-case">
            {{ cartStore.promoCodeBanner.description }}
          </h4>
        </li>
        <li v-for="tier in cartStore.promoCodeBanner?.tiers" :key="tier.id">
          <PromoCodeTierItem :tier="tier"/>
        </li>
      </ul>
    </div>
  </div>
</template>
<style scoped>
@import url("~/assets/css/app.css");
</style>
