<script setup lang="ts">
import {getNumberSuffix, maybePluralize} from '~/utils/helpers.js'

const props = defineProps({
  tier: {
    required: true
  }
});
</script>

<template>
  <span class="tw-font-agenda tw-text-[12px] tw-font-semibold tw-text-nl-yellow-100 sm:tw-whitespace-nowrap sm:tw-text-base ">
    <span class="tw-hidden sm:tw-inline">
      {{ `${props.tier.weekly_ordinal_number}${getNumberSuffix(props.tier.weekly_ordinal_number)}` }} week:
    </span>
    <span class="tw-font-bold" v-show="props.tier.type === 'dollars_off'"> ${{ props.tier.amount }} OFF </span>
    <span class="tw-font-bold" v-show="props.tier.type === 'percent_off'"> {{ props.tier.amount }}% OFF </span>
    <span class="tw-font-bold" v-show="props.tier.type === 'products_off'">
      {{ props.tier.amount }} {{ maybePluralize(props.tier.amount, 'item') }} OFF
    </span>
  </span>
</template>
<style scoped>
@import url("~/assets/css/app.css");
</style>
